<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>

    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Categories</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

          <div class="row mt-3">




            <form @submit.prevent="submitForm">


                <div class="col-md-12 mt-3">
                  <label>Parent Name</label>
                  <input type="text" disabled v-model="name" class="lss-form-control" placeholder="Name">
                </div>
                <div class="col-md-12 mt-3">
                  <label>Name</label>
                  <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">

                </div>


              <div class="row">
                <div class="col-md-12 mt-3 mb-3">
                  <button type="submit" class="btn btn-sent-yellow-lg float-end">Save</button>
                </div>
              </div>
            </form>








          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

          <div class="row mt-3">

            <table v-if="!isLoading" class="custom-table-bordered">
              <thead  class="custom-thead-light">
              <tr>
                <td width="5%" class="text-center">#</td>
                <td width="40%">Name</td>
                <td width="20%"  class="text-center">Created at</td>
                <td width="20%"  class="text-center">Link Exp Date</td>
                <td class="text-center">Action</td>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in SubCategories">
                <td class="text-center">{{ item.id }} </td>
                <td>
                  <a :href="'/Offers/'+categoryid+'/'+item.id">
                  {{ item.name }}
                  </a>
                </td>
                <td class="text-center">{{ item.InsertAt }} </td>
                <td width="20%"  class="text-center">
                  {{ item.end_date }}
                </td>
                <td class="text-center">
                  <b-dropdown id="dropdown-aria" variant="default" class="m-2">

                    <template #button-content>
                      <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                      </svg>
                    </template>


                    <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-b-modal="'modal-'+item.id">Get Link</b-dropdown-item-button>
                    <b-modal hide-footer :id="'modal-'+item.id" title="Get link">
                      <div class="row">
                          <div class="col-md-12">
                            <label>Link</label>
                            <input type="text" :value="'https://rca.ge/offer/'+item.id+'/'+item.hash" class="form-control" placeholder="Link">
                          </div>
                          <div class="col-md-12">
                            <label>Link Exp Date</label><br>
                            <date-picker v-model="link.date" valueType="format"></date-picker>
                          </div>

                      </div>
                      <b-button class="mt-3 float-end" style="margin-left:10px;" variant="success" block @click="saveModal(item.id,item.hash);">Save</b-button>
                      <b-button class="mt-3 float-end" variant="danger" block @click="closeModal();">Close</b-button>
                    </b-modal>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="deleteItem(item.id)">Delete</b-dropdown-item-button>
                  </b-dropdown>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from "axios";
export default {
  name: "EditEthnic",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      SumSendAmount:0,
      isSendLoading:false,
      categoryid:this.$route.params.id,
      Period:1,
      name:'',
      form:{
        name:'',

      },
      link:{
        date:''
      },
      SubCategories:[]
    }
  },
  components: {
    Loading,
    Header,
    DatePicker
  },
  methods:{

    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    LoadGetById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;

            this.name=res.data.name;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },
    LoadSubcategories(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/get_all_subcategories/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;

            this.SubCategories=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.post(process.env.VUE_APP_BACKEND_URL+'/categories/add_sub_categories/'+this.$route.params.id,this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            location.reload();
            //this.$router.push('/Categories');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    saveModal(itemid,hash){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.post(process.env.VUE_APP_BACKEND_URL+'/categories/save-link-date/'+itemid+'/'+hash,this.link, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            location.reload();
            //this.$router.push('/Categories');
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },
    deleteItem(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/categories/delete_sub_categories/'+id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.LoadSubcategories();
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },
    closeModal(){
      location.reload();
    }


  },
  mounted() {
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;

    this.LoadSubcategories();
    this.LoadGetById();
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>