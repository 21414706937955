import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signin from '../views/auth/Signin.vue'
import Signup from '../views/auth/Signup.vue'
import Recover from '../views/auth/Recover.vue'
import Dashboard from '../views/auth/Home.vue'
import Profile from '../views/auth/pages/profile/Profile.vue'

import Users from '../views/auth/pages/Users/Index.vue'
import CreateUser from '../views/auth/pages/Users/Create.vue'
import UserEdit from '../views/auth/pages/Users/Edit.vue'

import Eye from '../views/auth/pages/handbooks/eyecolor/index.vue'
import CreateEye from '../views/auth/pages/handbooks/eyecolor/create.vue'
import EditEye from '../views/auth/pages/handbooks/eyecolor/edit.vue'

import Hair from '../views/auth/pages/handbooks/haircolor/index.vue'
import CreateHair from '../views/auth/pages/handbooks/haircolor/create.vue'
import EditHair from '../views/auth/pages/handbooks/haircolor/edit.vue'

import Ethnic from '../views/auth/pages/handbooks/ethnic/index.vue'
import CreateEthnic from '../views/auth/pages/handbooks/ethnic/create.vue'
import EditEthnic from '../views/auth/pages/handbooks/ethnic/edit.vue'

import Languages from '../views/auth/pages/handbooks/languages/index.vue'
import CreateLanguages from '../views/auth/pages/handbooks/languages/create.vue'
import EditLanguages from '../views/auth/pages/handbooks/languages/edit.vue'

import Types from '../views/auth/pages/handbooks/types/index.vue'
import CreateTypes from '../views/auth/pages/handbooks/types/create.vue'
import EditTypes from '../views/auth/pages/handbooks/types/edit.vue'

import Tags from '../views/auth/pages/handbooks/tags/index.vue'
import CreateTags from '../views/auth/pages/handbooks/tags/create.vue'
import EditTags from '../views/auth/pages/handbooks/tags/edit.vue'

import Clothing from '../views/auth/pages/handbooks/clothing/index.vue'
import CreateClothing from '../views/auth/pages/handbooks/clothing/create.vue'
import EditClothing from '../views/auth/pages/handbooks/clothing/edit.vue'

import Application from '../views/auth/pages/application/index.vue'
import CreateApplication from '../views/auth/pages/application/create.vue'
import EditApplication from '../views/auth/pages/application/edit.vue'
import ApplicationView from '../views/auth/pages/application/show.vue'


import Categories from '../views/auth/pages/categories/index.vue'
import CreateCategories from '../views/auth/pages/categories/create.vue'
import EditCategories from '../views/auth/pages/categories/edit.vue'
import AddSubCategories from '../views/auth/pages/categories/AddSubCategories.vue'
import Offers from '../views/auth/pages/categories/Offers'

import Offer from '../views/auth/pages/application/offer'
import OfferLogin from '../views/auth/OfferLogin'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Signin
  },

  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/recover-password',
    name: 'Recover',
    component: Recover
  },
  {
    path:'/Home',
    name:'Dashboard',
    component: Dashboard
  },
  {
    path:'/Eye',
    name:"Eye",
    component: Eye
  },
  {
    path:'/Eye/Create',
    name:"CreateEye",
    component: CreateEye
  },
  {
    path:'/Eye/:id/Edit',
    name:"EditEye",
    component: EditEye
  },

  {
    path:'/Hair',
    name:"Hair",
    component: Hair
  },
  {
    path:'/Hair/Create',
    name:"CreateHair",
    component: CreateHair
  },
  {
    path:'/Hair/:id/Edit',
    name:"EditHair",
    component: EditHair
  },
  {
    path:'/Ethnic',
    name:"Ethnic",
    component: Ethnic
  },
  {
    path:'/Ethnic/Create',
    name:"CreateEthnic",
    component: CreateEthnic
  },
  {
    path:'/Ethnic/:id/Edit',
    name:"EditEthnic",
    component: EditEthnic
  },
  {
    path:'/Languages',
    name:"Languages",
    component: Languages
  },
  {
    path:'/Languages/Create',
    name:"CreateLanguages",
    component: CreateLanguages
  },
  {
    path:'/Languages/:id/Edit',
    name:"EditLanguages",
    component: EditLanguages
  },
  {
    path:'/Types',
    name:"Types",
    component: Types
  },
  {
    path:'/Types/Create',
    name:"CreateTypes",
    component: CreateTypes
  },
  {
    path:'/Types/:id/Edit',
    name:"EditTypes",
    component: EditTypes
  },

  {
    path:'/Tags',
    name:"Tags",
    component: Tags
  },
  {
    path:'/Tags/Create',
    name:"CreateTags",
    component: CreateTags
  },
  {
    path:'/Tags/:id/Edit',
    name:"EditTags",
    component: EditTags
  },
  {
    path:'/Clothing/:id/Edit',
    name:"EditClothing",
    component: EditClothing
  },
  {
    path:'/Clothing',
    name:"Clothing",
    component: Clothing
  },
  {
    path:'/Clothing/Create',
    name:"CreateClothing",
    component: CreateClothing
  },

  {
    path:'/Application',
    name:"Application",
    component: Application
  },
  {
    path:'/Application/Create',
    name:"CreateApplication",
    component: CreateApplication
  },
  {
    path:'/Application/:id/Edit',
    name:"EditApplication",
    component: EditApplication
  },
  {
    path:'/Application/:id/view',
    name:"ApplicationView",
    component: ApplicationView
  },

  {
    path:'/Users',
    name:"Users",
    component: Users
  },
  {
    path:'/Users/create',
    name:"CreateUser",
    component: CreateUser
  },
  {
    path:'/Users/:id/edit',
    name:'UserEdit',
    component: UserEdit
  },
  {
    path:'/Profile',
    name:"Profile",
    component: Profile
  },
  {
    path:'/Categories',
    name:"Categories",
    component: Categories
  },
  {
    path:'/Categories/Create',
    name:"CreateCategories",
    component: CreateCategories
  },
  {
    path:'/Categories/:id/Edit',
    name:"EditCategories",
    component: EditCategories
  },
  {
    path:'/Categories/:id/AddSubCategories',
    name:"AddSubCategories",
    component: AddSubCategories
  },
  {
    path:'/Offers/:id/:subcatid',
    name:"Offers",
    component: Offers
  },
  {
    path:'/offer/:id/:hash',
    name:"Offer",
    component:Offer
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name === 'Signin' || to.name==='Signup' || to.name==='Recover' || to.name==='Offer') {
    if(localStorage.getItem('user')){
      window.location.href='/Home';
    }else{
      next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    }
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (localStorage.getItem('user')) {

    next() // i'm logged in. carry on
  } else {
    if(to.name==='Signup'){
      next({ name: 'Signup' }) // always put your redirect as the default case
    }else{
      next({ name: 'Signin' }) // always put your redirect as the default case
    }

  }
})

export default router
