<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <header class="login_header">
      <div class="container p-0 pl-sm-3 pr-sm-3">
        <div class="row">
          <h3>Your Offers</h3>
          <div class="col-md-12 mb-4" v-for="item in ResponseData" v-if="item.application">


            <div class="card">
              <div class="row" >
                <div class="col-md-12 float-end mt-4 mb-4" v-if="item.check_status=='pendding'">
                  <button class="btn btn-warning float-end" @click="addOffer(item.application_id,'checked');" style="margin-right:20px;">+ Like</button>
                </div>
                <div class="col-md-12 float-end mt-4 mb-4" v-else>
                  <button class="btn btn-danger float-end" @click="addOffer(item.application_id,'pendding');" style="margin-right:20px;">- Unlike</button>
                </div>

                <div class="col-md-6" v-for="i in item.application.PicturePath">
                  <img   :src="i.path"  class="mx-auto mt-4 img-fluid" alt="">
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </header>

  </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "OfferLogin",
  data(){
    return{
      success:false,
      isLoading: false,
      fullPage: true,
      isActive: true,
      ResponseData:[]

    }
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {

    },
    CheckUser(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/check-guest/'+this.$route.params.id+'/'+this.$route.params.hash, {
        headers: {

        }
      })
          .then((res) => {

          })
          .catch((error) => {
            this.$router.push('/');
          });

      //alert(this.$route.params.hash);
    },
    addOffer(id,param){
      axios.post(process.env.VUE_APP_BACKEND_URL+'/add-offer/'+id, {
        status:param
      })
          .then((res) => {
              this.LoadData();
          })
          .catch((error) => {

          });
    },
    LoadData(){
      this.isLoading = true;

      axios.get(process.env.VUE_APP_BACKEND_URL+'/LoadOffersByCat/'+this.$route.params.id, {
        headers: {

        }
      })
          .then((res) => {
            this.isLoading = false;
            this.ResponseData=res.data;

          })
          .catch((error) => {
            this.isLoading = false;
          });
    },
    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors = errors;
    },
    hasError(fieldName) {
      return (fieldName in this.errors);
    },
    clearError(event) {
      delete this.errors[event.target.name]
    }
  },
  mounted() {
    this.CheckUser();
    this.LoadData();


    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }

}
</script>

<style scoped>

</style>