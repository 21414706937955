import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vue2Editor from "vue2-editor";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-select/dist/vue-select.css';
import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';
import {ValidationObserver} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';





import vSelect from 'vue-select'



Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('v-select', vSelect);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(Vue2Editor);








new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
