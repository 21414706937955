<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>

    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Create Application</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5 p-4">

          <div class="row mt-3">




            <form @submit.prevent="submitForm">
              <div class="row">

                <div class="col-md-6 mt-3">
                  <label>Pictures</label>
                  <vue-dropzone ref="myVueDropzone"  id="dropzone" :headers="dropzoneOptions.headers" v-on:vdropzone-success="uploadSuccessPhoto" :options="dropzoneOptions"></vue-dropzone>
                </div>

                <div class="col-md-6 mt-3">
                  <label>Video</label>
                  <vue-dropzone ref="myVueDropzone"  id="dropzone" :headers="dropzoneVideoOptions.headers" v-on:vdropzone-success="uploadSuccessVideo" :options="dropzoneVideoOptions"></vue-dropzone>
                </div>



                <div class="col-md-6">
                <label>Name</label>
                <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">

              </div>

              <div class="col-md-6">
                <label>Surname</label>
                <input type="text" v-model="form.surname" class="lss-form-control" placeholder="Surname">

              </div>

              <div class="col-md-6 mt-3">
                <label>Mobile</label>
                <input type="number" v-model="form.mobile" class="lss-form-control" placeholder="Mobile">

              </div>

              <div class="col-md-6 mt-3">
                <label>Email</label>
                <input type="text" v-model="form.email" class="lss-form-control" placeholder="Email">

              </div>
              <div class="col-md-6 mt-3">
                  <label>Facebook</label>
                  <input type="text" v-model="form.facebook" class="lss-form-control" placeholder="Facebook">

              </div>
                <div class="col-md-6 mt-3">
                  <label>Instagram</label>
                  <input type="text" v-model="form.instagram" class="lss-form-control" placeholder="Instagram">

                </div>
              <div class="col-md-3 mt-3">
                  <label class="col-md-12">Gender</label>
                  <select class="lss-form-select" v-model="form.gender">
                      <option value="">Choose Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                  </select>
              </div>
              <div class="col-md-3 mt-3">
                  <label class="col-md-12">Choose Type</label>
                  <select class="lss-form-select" v-model="form.type">
                    <option value="">Choose Type</option>
                    <option v-for="item in TypeData" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

              <div class="col-md-2 mt-3">
                  <label>Height</label>
                  <input type="number" v-model="form.height" class="lss-form-control" placeholder="Height">

                </div>
              <div class="col-md-2 mt-3">
                  <label>Weight</label>
                  <input type="number" v-model="form.weight" class="lss-form-control" placeholder="Weight">

                </div>

                <div class="col-md-2 mt-3">
                  <label>Year</label>
                  <input type="number" v-model="form.year" class="lss-form-control" placeholder="Year">

                </div>

              <div class="col-md-3 mt-3">
                  <label class="col-md-12">Eye Color</label>
                  <select class="lss-form-select" v-model="form.eye">
                    <option value="">Choose Color</option>
                    <option v-for="item in EyeData" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

              <div class="col-md-3 mt-3">
                  <label class="col-md-12">Hair Color</label>
                  <select class="lss-form-select" v-model="form.hair">
                    <option value="">Choose Color</option>
                    <option v-for="item in HairData" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

              <div class="col-md-3 mt-3">
                <label class="col-md-12">Etnic</label>
                <select class="lss-form-select" v-model="form.ethnic">
                  <option value="">Choose Etnic</option>
                  <option v-for="item in EthnicData" :value="item.id">{{ item.name }}</option>
                </select>
              </div>

              <div class="col-md-3 mt-3">
                <label class="col-md-12">Language</label>
                <v-select  multiple v-model="form.language" class="lss-form-select" placeholder="Choose Language" label="text" @input="LoadLanguage" :options="LangData"></v-select>
              </div>

                <div class="col-md-3 mt-3">
                  <label class="col-md-12">Cloting Size</label>
                  <select class="lss-form-select" v-model="form.clothing">
                    <option value="">Choose clothing size</option>
                    <option v-for="item in ClothingData" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>

                <div class="col-md-3 mt-3">
                  <label class="col-md-12">Foot size</label>
                  <select class="lss-form-select" v-model="form.foot">
                    <option value="">Choose foot size</option>
                    <option v-for="item in FootData" :value="item">{{ item }}</option>
                  </select>
                </div>

                <div class="col-md-6 mt-3">
                  <label class="col-md-12">Tags</label>
                  <v-select  multiple v-model="form.tag" class="lss-form-select" placeholder="Choose Tag" label="text" @input="LoadTags" :options="TagData"></v-select>
                </div>


              <div class="col-md-12 mt-3">
                <label class="col-md-12">Additional Skill</label>
                <VueEditor v-model="form.additional_skill" placeholder="Additional Skill"/>
              </div>


              <div class="col-md-12 mt-3">
                <label class="col-md-12">Work Experance</label>
                <VueEditor v-model="form.work_experance" placeholder="Work Experance"/>
              </div>


                <div class="col-md-12 mt-3 mb-3">
                  <button type="submit" class="btn btn-sent-yellow-lg float-end">Save</button>
                </div>
              </div>

            </form>








          </div>


        </div>




      </div>


    </div>
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'


export default {
  name: "CreateApplication",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      form:{
        name:'',
        surname:'',
        mobile:'',
        year:'',
        email:'',
        facebook:'',
        gender:'',
        height:'',
        weight:'',
        eye:'',
        hair:'',
        ethnic:'',
        language:'',
        experance:'',
        type:'',
        clothing:'',
        foot:'',
        additional_skill:'',
        pictures:[],
        videos:[],
        tag:[]
      },
      LangData:[],
      dropzoneOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/application/upload_photo',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
      dropzoneVideoOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/application/upload_video',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
      TypeData:{},
      EyeData:{},
      HairData:{},
      EthnicData:{},
      LanguageData:{},
      ClothingData:{},
      FootData:{},
      TagData:[]

    }
  },
  components: {
    Loading,
    Header,
    VueEditor,
    vueDropzone: vue2Dropzone
  },
  methods:{

    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/application/add',this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((res) => {
        this.isLoading = false;
        this.$router.push('/Application');
      })
      .catch((error) => {
        this.isLoading = false;
      });



    },
    LoadTypes(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/types/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
              this.TypeData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadTags(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/tags/LoadTag', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.TagData=res.data;
          })
          .catch((error) => {

          });

    },
    LoadEye(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/eye/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.EyeData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadHair(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/hair/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.HairData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadEtnic(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/ethnic/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.EthnicData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadLanguage(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/languages/LoadLanguage', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.LangData = res.data;
          })
          .catch((error) => {

          });

    },
    LoadClothingSize(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/clothing/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.ClothingData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadFootSize(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/foot/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.FootData=res.data;
          })
          .catch((error) => {

          });

    },
    uploadSuccessPhoto(file, response){
      this.form.pictures.push(response.success);
    },
    uploadSuccessVideo(file, response){
      this.form.videos.push(response.success);
    }


  },
  mounted() {
    this.LoadTypes();
    this.LoadTags();
    this.LoadEye();
    this.LoadHair();
    this.LoadEtnic();
    this.LoadLanguage();
    this.LoadClothingSize();
    this.LoadFootSize();
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
