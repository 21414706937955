<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <header class="login_header">
      <div class="container-fluid p-0 pl-sm-3 pr-sm-3">
        <div class="row">
          <div class="col-12">
            <a href="/">
            </a>
          </div>
        </div>
      </div>
    </header>
    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <form v-on:submit.prevent="submitForm" @keydown="clearError" class="rowpt-70">
              <div class="col-12">
                <h1>Sign In</h1>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="login-email">Email</label>
                <input id="login-email" type="email" name="email" v-model="form.email"  class="lss-form-control" placeholder="example@example.com">

                <div class="form-validation text-danger" v-if="hasError('email')" id="login-error-mail"> {{ errors['email'][0]}}</div>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="password">Password</label>
                <div class="custom-input-group input-group-merge">
                  <input id="password" :type="InputType"  name="password" v-model="form.password" class="lss-form-control" placeholder="********">
                  <div class="input-group-append">
                    <div class="input-group-text"  v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-validation text-danger" v-if="hasError('password')" id="login-error-password">
                  {{ errors['password'][0]}}
                </div>
              </div>
              <div class="col-12 mt-20">
                <div>
                  <a href="/recover-password"><p>Lost Password?</p></a>
                </div>
              </div>
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-20">
                <button class="btn-sent-yellow-lg d-block d-sm-none">Sign In</button>
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">

                </div>
                <button  class="btn-sent-yellow-lg d-none d-sm-block">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'

export default {
  name: 'Signup',
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isActive: true,
      StatusPassword:'fas fa-eye-slash',
      InputType:'password',
      error:false,
      errors:{},
      form: {
        email: '',
        password: '',
      }
    }
  },
  components: {
    Loading
  },
  methods:{
    onCancel() {

    },
    show_password: function() {

      if(this.isActive===true){
        this.isActive=false;
        this.InputType='text';

      }else{
        this.isActive=true;
        this.InputType='password';

      }

    },
    submitForm(){
      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/login', this.form)
          .then((res) => {
            this.isLoading = false
            localStorage.setItem('user',JSON.stringify(res.data))
            if(res.data.active_status==='end_user' && this.$router.currentRoute.name!='Billing'){
              this.$router.push('/Billing')
            }else{
              this.$router.push('/Home')
            }
           // window.location ='/Home';

          })
          .catch((error) => {
            this.isLoading = false
            var error_response=error.response.data;
            if(error.response.status==422){
              this.setErrors(error.response.data.errors);
            }else{
              this.setErrors(error.response.data.message);
            }
          });
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    }
  },
  mounted() {



    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>
