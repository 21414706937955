<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">

      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Create User</h5>
            </div>

          </div>


          <div class="card mt-4">


            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" class="rowpt-70">
                <div class="row p-4">
                  <div class="col-md-6">

                    <label>Name</label>
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                      <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">
                      <span  class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-6">
                    <label>Email</label>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                      <input type="text" v-model="form.email" class="lss-form-control" placeholder="Email">
                      <span  class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>


                  <div class="col-md-6 mt-3">

                    <label>Password</label>
                    <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                      <input type="password" v-model="form.password" class="lss-form-control" placeholder="Password">
                      <span  class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>




                  <div class="col-md-12 mt-3 text-end">
                    <button type="submit" class="btn-sent-yellow-lg" >+ Save</button>
                  </div>


                </div>
              </form>
            </ValidationObserver>

          </div>


        </div>





      </div>

    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "UserEdit",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      error:false,
      errors:{},
      invalid:'',
      form:{
        name:'',
        email:'',
        password:''

      }
    }
  },
  components: {
    Loading,
    Header
  },
  methods: {

    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL+'/users/update_user/'+this.$route.params.id,{'data':this.form}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/Users');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    },
    getById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/users/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            console.log(res.data);
            this.form=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    }


  },
  mounted() {
    this.getById();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>

</style>