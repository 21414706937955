<template>
  <div>
  <loading :active.sync="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage"></loading>

  <Header/>
    <div class="main_dashboard">


      <form v-on:submit.prevent="submitForm" @keydown="clearError" class="rowpt-70">
      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Profile Settings</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

        <div class="row mt-5">





          <div class="col-md-3 border-right">
            <div class="d-flex flex-column align-items-center text-center">
              <img class="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg">
              <span class="font-weight-bold">
                {{ form.name }}
              </span>
              <span class="text-black-50">
                {{ form.email }}
              </span>
              <span>
              </span>
            </div>
          </div>

          <div class="col-md-9 justify-content-md-end">
            <div class="row">
              <div class="d-flex justify-content-between align-items-center mb-3 mt-5">
                <h4 class="text-right">Profile Settings</h4>
              </div>
              <div class="col-md-12" v-if="alarm">
                  <div class="row">
                    <div class="col-md-12 me-auto">
                      <div class="alert alert-success text-center">Your password successfully updated.</div>
                    </div>
                  </div>
              </div>
              <div class="col-md-6 border-right">
                <div >

                  <div class="row mt-2">
                    <div class="col-md-12"><label class="labels">Name</label>
                      <input type="text" class="lss-form-control" placeholder="Name" disabled v-model="form.name">
                    </div>

                  </div>
                  <div class="row mt-3">


                    <div class="col-md-12">
                      <label class="labels">Email</label>
                      <input type="text" class="lss-form-control" v-model="form.email" disabled placeholder="Email" value="">
                    </div>
                  </div>


                </div>
              </div>
              <div class="col-md-6  mt-2">
                <div >

                  <div class="col-md-12">
                    <label class="labels">
                      Password
                    </label>
                    <input type="password" class="lss-form-control" v-model="form.password" placeholder="Password" value="">
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="labels">
                      Repeat Password
                    </label>
                    <input type="password" class="lss-form-control" v-model="form.repassword"  placeholder="Repeat Password" value="">
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div class="row">
          <div class="col-md-12 mb-5 mt-4">
            <button  class="btn-sent-yellow-lg  float-end">+ Save</button>
          </div>
        </div>
        </div>




      </div>
      </form>


    </div>



  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";
export default {
  name: "Profile",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      form:{
        name:'',
        email:'',
        password:'',
        repassword:''
      },
      alarm:false,
      error:false,
      errors:{},
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {
    },
    GetUser(){
      const user_info=JSON.parse(localStorage.getItem('user'));
      this.form.name=user_info.user.name;
      this.form.email=user_info.user.email;
    },
    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL+'/change_password',this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.alarm=true;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){
      return (fieldName in this.errors);
    },
    clearError(event){
      delete this.errors[event.target.name]
    }

  },

  mounted() {
    this.GetUser();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>

</style>