<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>

    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Ethnic</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

          <div class="row mt-3">




            <form @submit.prevent="submitForm">
              <div class="col-md-12">
                <label>Name</label>
                <input type="text" v-model="form.name" class="lss-form-control" placeholder="Name">

              </div>

              <div class="row">
                <div class="col-md-12 mt-3 mb-3">
                  <button type="submit" class="btn btn-sent-yellow-lg float-end">Save</button>
                </div>
              </div>
            </form>








          </div>


        </div>




      </div>


    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "EditEthnic",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      form:{
        name:''
      }
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    LoadGetById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/clothing/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;

            this.form=res.data;
          })
          .catch((error) => {
            this.isLoading = false;


          });
    },

    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.put(process.env.VUE_APP_BACKEND_URL+'/clothing/edit/'+this.$route.params.id,this.form, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/Clothing');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    }


  },
  mounted() {
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;


    this.LoadGetById();
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>