<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>

    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">View Application</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5 p-4">

          <div class="row mt-3">

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <h5>Pictures</h5>
                  <img v-for="item in form.picture" :src="item.file.path" class="float-left" style="height:250px;float:left;">

                </div>
              </div>




              <div class="row mt-3" v-if="form.video.length>0">
                <h5>Video</h5>
                <div class="col-md-12 mt-2" style="position:relative;" v-for="item in form.video">
                  <div class=" border p-1 bg-light">
                    <video width="100%" height="300" controls>
                      <source :src="item.file.path" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>

                  </div>

                </div>
              </div>
            </div>

            <div class="col-md-5">
                <h5>Detail Information</h5>
                <table class="custom-table-bordered mt-3" style=" word-break: break-word;">

                  <tr>
                    <td class="p-3">Name:</td>
                    <td class="p-3">{{ form.name }} {{ form.surname }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Email:</td>
                    <td class="p-3">{{ form.email }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Mobile:</td>
                    <td class="p-3">{{ form.mobile }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Facebook:</td>
                    <td class="p-3">
                      <a :href="form.facebook">
                        {{ form.facebook }}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td class="p-3">Instagram:</td>
                    <td class="p-3">
                      <a :href="form.instagram">
                      {{ form.instagram }}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td class="p-3">Gender:</td>
                    <td class="p-3">{{ form.gender }}</td>
                  </tr>
                  <tr>
                    <td class="p-3">Actor Type:</td>
                    <td class="p-3">{{ form.actor_type_name }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Height:</td>
                    <td class="p-3">{{ form.height }} CM</td>
                  </tr>

                  <tr>
                    <td class="p-3">Weight:</td>
                    <td class="p-3">{{ form.weight }} KG</td>
                  </tr>

                  <tr>
                    <td class="p-3">Age:</td>
                    <td class="p-3">{{ form.age }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Eye Color:</td>
                    <td class="p-3">{{ form.eye_color_name }}</td>
                  </tr>
                  <tr>
                    <td class="p-3">Hair Color:</td>
                    <td class="p-3">{{ form.hair_color_name }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Etnic:</td>
                    <td class="p-3">{{ form.ethnic_name }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Language:</td>
                    <td class="p-3">{{ form.language_coma }}</td>
                  </tr>
                  <tr>
                    <td class="p-3">Clothing size:</td>
                    <td class="p-3">{{ form.clothing_size }}</td>
                  </tr>
                  <tr>
                    <td class="p-3">Foot size:</td>
                    <td class="p-3">{{ form.foot }}</td>
                  </tr>

                  <tr>
                    <td class="p-3">Tags:</td>
                    <td class="p-3">{{ form.tag_coma }}</td>
                  </tr>
                </table>
            </div>
            <div class="col-md-7">
              <h5></h5>




              <div classs="row">
                <h5 class="mt-3">Additional Skill</h5>
                <div class="col-md-12 mt-3" v-html="form.additional_skill">

                </div>

                <h5 class="mt-3">Work Experance</h5>
                <div class="col-md-12 mt-3" v-html="form.work_experance">

                </div>
              </div>

            </div>

          </div>


        </div>




      </div>


    </div>
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'


export default {
  name: "ApplicationView",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      form:{
        name:'',
        surname:'',
        mobile:'',
        email:'',
        facebook:'',
        gender:'',
        height:'',
        weight:'',
        eye:'',
        hair:'',
        ethnic:'',
        language:'',
        experance:'',
        type:'',
        clothing:'',
        foot:'',
        additional_skill:'',
        pictures:[],
        videos:[]
      },
      dropzoneOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/application/upload_photo',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
      dropzoneVideoOptions: {

        url: process.env.VUE_APP_BACKEND_URL+'/application/upload_video',
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).access_token}`
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,

      },
      TypeData:{},
      EyeData:{},
      HairData:{},
      EthnicData:{},
      LanguageData:{},
      ClothingData:{},
      FootData:{},
      ApplicationData:{}

    }
  },
  components: {
    Loading,
    Header,
    VueEditor,
    vueDropzone: vue2Dropzone
  },
  methods:{
    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    LoadApplication(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/application/get_by_id/'+this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.form=res.data;

          })
          .catch((error) => {
            this.isLoading = false;
          });

    },

  },
  mounted() {
    this.LoadApplication();

    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
