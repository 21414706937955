<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>
    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Categories</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

          <div class="row mt-3">


            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <input type="text" class="lss-form-control" v-on:keyup="getFilter" v-model="keyword" placeholder="Search..." aria-label="Search">
                </div>
              </div>
            </div>
            <div class="col-md-2 mb-3">
              <a href="/Categories/Create">
                <button class="btn btn-sent-yellow-lg float-end">Add new</button>
              </a>
            </div>


            <table v-if="!isLoading" class="custom-table-bordered">
              <thead  class="custom-thead-light">
              <tr>
                <td width="5%" class="text-center">#</td>
                <td width="40%" >Name</td>
                <td width="20%" class="text-center">Author</td>
                <td width="20%"  class="text-center">Created at</td>
                <td class="text-center">Action</td>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in ResponseData.data">
                <td class="text-center">{{ item.id }} </td>
                <td style="color:#2b71fb;text-decoration:underline;cursor:pointer;" v-on:click="AddSubcategories(item.id)">{{ item.name }} </td>
                <td class="text-center">{{ item.Author }} </td>
                <td class="text-center">{{ item.InsertAt }} </td>
                <td class="text-center">
                  <b-dropdown id="dropdown-aria" variant="default" class="m-2">

                    <template #button-content>
                      <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                      </svg>
                    </template>


                    <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="AddSubcategories(item.id)">Add subcategories</b-dropdown-item-button>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="EditItem(item.id)">Edit</b-dropdown-item-button>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="deleteItem(item.id)">Delete</b-dropdown-item-button>
                  </b-dropdown>
                </td>
              </tr>
              </tbody>
            </table>





          </div>

          <div class="row mt-2">
            <div class="col-md-3">
              <pagination :data="ResponseData" @pagination-change-page="LoadData"></pagination>
            </div>
          </div>

        </div>




      </div>


    </div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "Dashboard",
  data() {
    return {
      keyword:'',
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      ResponseData:{}
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    LoadData(page = 1){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/load_data?page=' + page,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });
    },
    deleteItem(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/categories/delete/'+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.LoadData();
          });
    },
    EditItem(id){
      this.$router.push({ name: 'EditCategories', params: { id: id  } });
    },
    AddSubcategories(id){
      this.$router.push({ name: 'AddSubCategories', params: { id: id  } });
    },
    getFilter(){
      if(this.keyword.length>=3 || this.keyword===''){
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        this.isLoading = true;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/load_data?'+'keyword='+this.keyword,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              this.ResponseData = response.data;
              this.isLoading = false
            });
      }
    },



  },
  mounted() {

    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;



    this.LoadData();
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>