<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>

    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Offers</h5>
            </div>


          </div>


        </div>




        <div class="container-fluid rounded border bg-white mt-1 mb-5">
          <form ref="form">
            <div class="row">
              <div class="col-md-3 mt-3">
                <label>
                  Status
                </label>
                <select class="lss-form-select" name="status" v-model="form.status">
                  <option value="">Choose Status</option>
                  <option value="pendding">Pendding</option>
                  <option value="checked">Liked</option>
                </select>
              </div>

              <div class="col-md-2 mt-5">
                <button class="btn btn-warning" v-on:click="LoadGetById">Filter</button>
              </div>
              <div class="col-md-7 float-end">
                <div class="btn btn-primary float-end mt-5" v-b-modal="'modal-sms'">Send SMS</div>
                <b-modal hide-footer :id="'modal-sms'" title="Send sms">

                  <div class="col-md-12">
                    <label>Message</label>
                    <textarea class="form-control"></textarea>
                  </div>

                  <b-button class="mt-3 float-end" style="margin-left:10px;" variant="warning">Send</b-button>
                  <b-button class="mt-3 float-end" variant="danger" block @click="closeModal();">Close</b-button>
                </b-modal>
              </div>
            </div>
          </form>


          <div class="row mt-3 mb-3">

            <div class="col-md-3" v-for="item in ResponseData"  v-if="item.application">
              <div class="card">
                <img  @click="ViewItem(item.application.id)" :src="item.application.FirstPicture" style="height:200px;cursor:pointer;" class="mx-auto mt-4 img-fluid" alt="">
                <div class="card-body">
                  <div class="card-text mb-4">
                    <h5>{{ item.application.name }} {{ item.application.surname }}</h5>
                  </div>

                  <div class="float-start">

                    <div class="alert alert-warning" v-if="item.check_status=='pendding'" style="padding: 5px;font-size: 14px;">Pendding</div>
                    <div class="alert alert-success" v-if="item.check_status=='checked'" style="padding: 5px;font-size: 14px;">Liked offer</div>

                  </div>
                  <b-dropdown id="dropdown-aria" variant="default" class="float-end">

                    <template #button-content>
                      <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                      </svg>
                    </template>


                    <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1">Send sms</b-dropdown-item-button>

                  </b-dropdown>
                </div>
              </div>
            </div>



          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "Offers",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      name:'',
      form:{
        name:'',
        status:this.$route.query.status
      },
      SubCategories:[],
      ResponseData:[]
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{
    closeModal(){
     location.reload();
    },
    submit(){
        this.isLoading = true;
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/application/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then((res) => {
              this.isLoading = false;
              this.ResponseData=res.data;

            })
            .catch((error) => {
              this.isLoading = false;
            });
    },
    onCancel() {

    },
    ViewItem(id){
      this.$router.push({ name: 'ApplicationView', params: { id: id  } });
    },
    LoadGetById(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/application/LoadOffers/'+this.$route.params.id+'/'+this.$route.params.subcatid, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params:{
          status:this.$route.query.status,
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.ResponseData=res.data;

          })
          .catch((error) => {
            this.isLoading = false;
          });
    },



  },
  mounted() {
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;

    this.LoadGetById();
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>