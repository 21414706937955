<template>
  <div class="dashboard-menu fixed-top">
    <div class="sent_nav_bar_container">


      <div  class="pt-1">

        <b-navbar  toggleable="lg" >
          <b-navbar-brand href="#">
              <h3>Actor CRM</h3>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="w-100">
              <b-nav-item href="/Application">Applications</b-nav-item>

              <b-nav-item href="/Categories">Spec Categories</b-nav-item>


              <b-nav-item-dropdown>
                <template #button-content>
                  Handbooks
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Group_4602" data-name="Group 4602">
                      <g id="Group_4604" data-name="Group 4604">
                        <g id="Rectangle_1003" data-name="Rectangle 1003" fill="#707070" stroke="#292929" stroke-width="2" opacity="0">
                          <rect width="15" height="15" stroke="none"/>
                          <rect x="1" y="1" width="13" height="13" fill="none"/>
                        </g>
                        <path id="Path_2" data-name="Path 2" d="M7.5,3.75,3.75,0,0,3.75" transform="translate(11.25 9.687) rotate(180)" fill="none" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>

                </template>
                <b-dropdown-item href="/Eye">Eye color</b-dropdown-item>
                <b-dropdown-item href="/Hair">Hair color</b-dropdown-item>
                <b-dropdown-item href="/Ethnic">Ethnic origin</b-dropdown-item>
                <b-dropdown-item href="/Languages">Foreign languages</b-dropdown-item>
                <b-dropdown-item href="/Types">Actor Types</b-dropdown-item>
                <b-dropdown-item href="/Clothing">Clothing Size</b-dropdown-item>
                <b-dropdown-item href="/Tags">Tags</b-dropdown-item>



              </b-nav-item-dropdown>
              <b-nav-item href="/Users">User Managment</b-nav-item>

            </b-navbar-nav>




            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

              <b-nav-item-dropdown right>
                <!-- Using 'button-content' slot -->

                <template #button-content>
                  {{ name }}
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <g id="Group_4602" data-name="Group 4602">
                      <g id="Group_4604" data-name="Group 4604">
                        <g id="Rectangle_1003" data-name="Rectangle 1003" fill="#707070" stroke="#292929" stroke-width="2" opacity="0">
                          <rect width="15" height="15" stroke="none"/>
                          <rect x="1" y="1" width="13" height="13" fill="none"/>
                        </g>
                        <path id="Path_2" data-name="Path 2" d="M7.5,3.75,3.75,0,0,3.75" transform="translate(11.25 9.687) rotate(180)" fill="none" stroke="#292929" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>
                </template>
                <b-dropdown-item href="/Profile">Profile</b-dropdown-item>
                <b-dropdown-item v-on:click="logout">Sign Out</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>





        </b-navbar>
      </div>


    </div>



  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      name:'',
      isActive:'',
      role:''
    }
  },
  mounted(){
    try {
      JSON.parse(localStorage.getItem('user'))

    }catch(e) {
      localStorage.removeItem('user');
      this.$router.push('/signin')
    }
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;
    const token = JSON.parse(localStorage.getItem('user')).access_token;



    axios.post(process.env.VUE_APP_BACKEND_URL+'/check-user',{'data':localStorage.getItem('user')}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
        .then((res) => {



        })
        .catch((error) => {

            localStorage.removeItem('user');
            this.$router.push('/signin')

        });



  },
  methods:{
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    }
  }
}
</script>

<style scoped>

</style>