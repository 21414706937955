<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <header class="login_header">
      <div class="container-fluid p-0 pl-sm-3 pr-sm-3">
        <div class="row">
          <div class="col-12">
            <a href="/">
              <svg id="Component_1_1" data-name="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="216.13" height="60.86" viewBox="0 0 216.13 60.86">
                <defs>
                  <linearGradient id="linear-gradient" x1="0.5" y1="-0.807" x2="0.5" y2="1.855" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ffc400"/>
                    <stop offset="1" stop-color="#c822ff"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-2" y1="-0.016" y2="1.235" xlink:href="#linear-gradient"/>
                  <linearGradient id="linear-gradient-3" y1="-0.212" y2="1.039" xlink:href="#linear-gradient"/>
                  <linearGradient id="linear-gradient-4" x1="0.5" y1="-0.063" x2="0.5" y2="1.067" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#ffc400"/>
                    <stop offset="1" stop-color="#e6abff"/>
                  </linearGradient>
                </defs>
                <text id="SENT" transform="translate(70.13 49.744)" fill="#011122" font-size="53" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">SENT</tspan></text>
                <g id="icons8-synchronize">
                  <path id="Path_1" data-name="Path 1" d="M32.955,44.91A11.955,11.955,0,1,0,21,32.955,11.969,11.969,0,0,0,32.955,44.91Zm0-21.737a9.782,9.782,0,1,1-9.782,9.782A9.792,9.792,0,0,1,32.955,23.174Z" transform="translate(-2.524 -2.525)" fill="url(#linear-gradient)"/>
                  <path id="Path_2" data-name="Path 2" d="M12.489,40.647a6.519,6.519,0,0,0,0-12.432A22.671,22.671,0,0,1,33.345,11.653v3.262a2.1,2.1,0,0,0,1.068,1.835,2.189,2.189,0,0,0,2.188,0l6.03-3.5a3.132,3.132,0,0,0,0-5.444L36.6,4.3a2.186,2.186,0,0,0-2.188,0,2.1,2.1,0,0,0-1.068,1.835V9.48a24.832,24.832,0,0,0-23.03,18.441,6.513,6.513,0,0,0,0,13.02A24.958,24.958,0,0,0,20.053,54.88L21.3,53.1A22.776,22.776,0,0,1,12.489,40.647ZM41.537,9.679a.958.958,0,0,1,0,1.685l-6.02,3.489L35.51,6.176ZM6.174,34.431a4.347,4.347,0,1,1,4.347,4.347A4.352,4.352,0,0,1,6.174,34.431Z" transform="translate(-4 -4.001)" fill="url(#linear-gradient-2)"/>
                  <path id="Path_3" data-name="Path 3" d="M63.213,33.634a6.523,6.523,0,0,0-6.317-6.51A24.958,24.958,0,0,0,47.16,13.185l-1.252,1.777a22.784,22.784,0,0,1,8.815,12.456,6.52,6.52,0,0,0,0,12.433,22.673,22.673,0,0,1-20.86,16.56V53.15A2.1,2.1,0,0,0,32.8,51.316a2.183,2.183,0,0,0-2.188,0l-6.03,3.5a3.131,3.131,0,0,0,0,5.443l6.029,3.5a2.189,2.189,0,0,0,2.188,0,2.1,2.1,0,0,0,1.068-1.833V58.585A24.834,24.834,0,0,0,56.9,40.145,6.525,6.525,0,0,0,63.213,33.634ZM31.695,61.884l-6.019-3.5a.958.958,0,0,1,0-1.685l6.02-3.552Zm25-23.9a4.347,4.347,0,1,1,4.347-4.347A4.352,4.352,0,0,1,56.692,37.981Z" transform="translate(-2.35 -3.203)" fill="url(#linear-gradient-3)"/>
                  <path id="Path_4" data-name="Path 4" d="M32.347,28a4.347,4.347,0,1,0,4.347,4.347A4.347,4.347,0,0,0,32.347,28Z" transform="translate(-1.916 -1.917)" fill="url(#linear-gradient-4)"/>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </header>

    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <form v-on:submit.prevent="submitForm" @keydown="clearError" class="row">
              <div class="col-12">
                <h1>Sign Up</h1>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="name">Name</label>
                <input id="name" type="text" name="name" v-model="form.name"  class="lss-form-control" placeholder="Nick Monckey">
                <div class="form-validation text-danger" v-if="hasError('name')" > {{ errors['name'][0]}}</div>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="email">Email</label>
                <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control" placeholder="example@example.com">
                <div class="form-validation text-danger" v-if="hasError('email')"> {{ errors['email'][0]}}</div>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="password">Password</label>
                <div class="custom-input-group input-group-merge">
                  <input id="password" :type="InputType" name="password" v-model="form.password" class="lss-form-control" placeholder="********">
                  <div id="changePasswordView" class="input-group-append">
                    <div class="input-group-text"  v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="form-validation text-danger" v-if="hasError('password')"> {{ errors['password'][0]}}</div>
              </div>
              <div class="col-12 custom-form-group">
                <label class="custom-input-label" for="re-password">Re-Password</label>
                <div class="custom-input-group input-group-merge">
                  <input id="re-password" :type="InputType" name="repassword" v-model="form.repassword" class="lss-form-control" placeholder="********">
                  <div class="input-group-append">
                    <div class="input-group-text"  v-on:click="show_password">
                      <div :class="isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye-slash"></i>
                      </div>
                      <div :class="!isActive ? 'd-block' : 'd-none'">
                        <i class="fas fa-eye"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                <button class="btn-sent-yellow-lg d-block d-sm-none">Sign In</button>
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                  <div class="mr-0 mr-sm-3">Already have an account?</div>
                  <a href="/signin" class="ms-1">Sign In</a>
                </div>
                <button class="btn-sent-yellow-lg d-none d-sm-block">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'Signup',
  data() {
    return {
      isLoading: false,
      fullPage: true,
      isActive: true,
      StatusPassword:'fas fa-eye-slash',
      InputType:'password',
      error: false,
      errors: {},
      form: {
        name: '',
        email: '',
        password: '',
        repassword: '',
      }

    }
  },
  components: {
    Loading
  },
  methods:{
    onCancel() {

    },
    show_password: function() {

      if(this.isActive===true){
        this.isActive=false;
        this.InputType='text';

      }else{
        this.isActive=true;
        this.InputType='password';

      }

    },
    submitForm(){
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/signup', this.form)
          .then((res) => {
            localStorage.setItem('user',JSON.stringify(res.data))
            window.location ='/Home';
          })
          .catch((error) => {
            var error_response=error.response.data;
            if(error.response.status==422){
              this.setErrors(error.response.data.errors);
            }else{
              this.setErrors(error.response.data.message);
            }
          });
    },
    onFailure(message){
      this.error=true;
    },
    setErrors(errors){
      this.errors=errors;
    },
    hasError(fieldName){

      return (fieldName in this.errors);
    },
    clearError(event){

      delete this.errors[event.target.name]
    }
  },
  mounted() {

    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>
