<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>
    <div class="main_dashboard">


      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Application</h5>
            </div>


          </div>


        </div>


        <div class="container-fluid rounded border bg-white mt-1 mb-5">

          <div class="row mt-3">


            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6 mt-2">
                  <b-button @click="showModal" variant="light" v-b-toggle href="#filter" @click.prevent>
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24 M24,24H0" fill="none"/><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none"/></g></svg>
                  Filter
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-md-2 mb-3">
              <a href="/application/Create">
                <button class="btn btn-sent-yellow-lg float-end">Add new</button>
              </a>
            </div>


            <b-modal ref="my-modal" size="xl" hide-footer title="Search">
              <div class="d-block">
                <h3>Parametrs</h3>
              </div>

              <form ref="form">
              <div class="d-block">
                <div class="row mt-3">
                  <div class="col-md-3">
                    <label>Name</label>
                    <input type="text" name="name" v-model="form.name" class="lss-form-control" placeholder="Name">
                  </div>

                  <div class="col-md-3">
                    <label>Surname</label>
                    <input type="text" name="surname" v-model="form.surname" class="lss-form-control" placeholder="Surname">
                  </div>

                  <div class="col-md-3">
                    <label>Mobile</label>
                    <input type="number" name="mobile" v-model="form.mobile" class="lss-form-control" placeholder="Mobile">
                  </div>

                  <div class="col-md-3">
                    <label>Email</label>
                    <input type="text" name="email" v-model="form.email" class="lss-form-control" placeholder="Email">
                  </div>
                  </div>


                <div class="row mt-3">

                  <div class="col-md-3">
                    <label>Min Height</label>
                    <input type="number" name="min_height" v-model="form.min_height" class="lss-form-control" placeholder="Min Height">
                  </div>

                  <div class="col-md-3">
                    <label>Max Height</label>
                    <input type="number" name="max_height" v-model="form.max_height" class="lss-form-control" placeholder="Max Height">
                  </div>

                  <div class="col-md-3">
                    <label>Min Weight</label>
                    <input type="number" name="min_weight" v-model="form.min_weight" class="lss-form-control" placeholder="Min Weight">
                  </div>

                  <div class="col-md-3">
                    <label>Max Weight</label>
                    <input type="number" name="max_weight" v-model="form.max_weight" class="lss-form-control" placeholder="Max Weight">
                  </div>
                </div>

                <div class="row mt-3">


                  <div class="col-md-3">
                    <label>Gender</label>
                    <select class="lss-form-select" name="gender" v-model="form.gender">
                      <option value="">Choose Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>


                  <div class="col-md-3">
                    <label>Type</label>
                    <select class="lss-form-select" name="type" v-model="form.type">
                      <option value="">Choose Type</option>
                      <option v-for="item in TypeData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>


                  <div class="col-md-3">
                    <label>Eye</label>
                    <select class="lss-form-select" name="eye" v-model="form.eye">
                      <option value="">Choose Color</option>
                      <option v-for="item in EyeData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>


                  <div class="col-md-3">
                    <label>Hair</label>
                    <select class="lss-form-select" name="hair" v-model="form.hair">
                      <option value="">Choose Color</option>
                      <option v-for="item in HairData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>




                </div>



                <div class="row mt-3">


                  <div class="col-md-3">
                    <label>Ethnic</label>
                    <select class="lss-form-select" name="ethnic" v-model="form.ethnic">
                      <option value="">Choose Etnic</option>
                      <option v-for="item in EthnicData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <label>Language</label>
                    <select class="lss-form-select" name="language" v-model="form.language">
                      <option value="">Choose Language</option>
                      <option v-for="item in LanguageData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>



                  <div class="col-md-3">
                    <label>Clothing</label>
                    <select class="lss-form-select" name="clothing" v-model="form.clothing">
                      <option value="">Choose clothing size</option>
                      <option v-for="item in ClothingData" :value="item.id">{{ item.name }}</option>
                    </select>
                  </div>


                  <div class="col-md-3">
                    <label>Foot size</label>
                    <select class="lss-form-select" name="foot" v-model="form.foot">
                      <option value="">Choose foot size</option>
                      <option v-for="item in FootData" :value="item">{{ item }}</option>
                    </select>
                  </div>

                </div>

              </div>

                <b-button class="mt-3 float-end mr-2 " v-on:click="submit" variant="primary">Search</b-button>
                <b-button class="mt-3 float-end mr-10 " v-on:click="ClearFilter" variant="warning">Clear Filter</b-button>

              </form>
            </b-modal>


<!--            <form ref="form">
              <input name="vitalInformation" v-model="vital">
              <input name="vitalInformation2" v-model="vital2">
              <a href="#" v-on:click="submit">SUBMIT</a>
            </form>-->




<!--              <div class="col-md-12">
              <table class="custom-table-bordered">
                <thead class="custom-thead-light">
                  <tr>
                    <td class="text-center">#</td>
                    <td>Name</td>
                    <td class="text-center">Gender</td>
                    <td class="text-center">Type</td>
                    <td class="text-center">Height</td>
                    <td class="text-center">Weight</td>
                    <td class="text-center">Eye</td>
                    <td class="text-center">Hair</td>
                    <td class="text-center">Ethnic</td>
                    <td class="text-center">Language</td>
                    <td class="text-center">Clothing</td>
                    <td class="text-center">Foot Size</td>
                    <td class="text-center">Created At</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="item in ResponseData.data">
                  <td class="text-center">
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.name }} {{ item.surname }}<br>
                    {{ item.email }}<br>
                    {{ item.mobile }}<br>

                  </td>
                  <td class="text-center">
                    {{ item.gender}}
                  </td>
                  <td class="text-center">
                    {{ item.TypeName }}
                  </td>
                  <td class="text-center">
                    {{ item.height}} CM
                  </td>
                  <td class="text-center">
                    {{ item.weight}} KG
                  </td>
                  <td class="text-center">
                    {{ item.EyeColor }}
                  </td>
                  <td class="text-center">
                    {{ item.HairColor }}
                  </td>
                  <td class="text-center">
                    {{ item.EthnicName }}
                  </td>
                  <td class="text-center">
                    {{ item.LanguageName }}
                  </td>
                  <td class="text-center">
                    {{ item.ClothingName }}
                  </td>
                  <td class="text-center">
                    {{ item.foot }}
                  </td>
                  <td class="text-center">{{ item.CreateDate }}</td>
                  <td>
                    <b-dropdown id="dropdown-aria" variant="default" class="m-2">

                      <template #button-content>
                        <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                          <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                          <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                          <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                        </svg>
                      </template>


                      <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                      <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="ViewItem(item.id)">View</b-dropdown-item-button>
                      <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="EditItem(item.id)">Edit</b-dropdown-item-button>
                      <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="deleteItem(item.id)">Delete</b-dropdown-item-button>
                    </b-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
              </div>-->

           <div class="col-md-3" v-for="item in ResponseData.data">
              <div class="card">
                <img :src="item.FirstPicture" style="height:200px;cursor:pointer;" @click="ViewItem(item.id)" class="mx-auto mt-4 img-fluid" alt="">
                <div class="card-body">
                  <div class="card-text mb-4">
                    <h5>{{ item.name }} {{ item.surname }}</h5>
                  </div>

                  <div class="float-start">
                    <button class="btn btn-warning" v-b-modal="'modal-'+item.id" >+ Add</button>
                    <b-modal hide-footer :id="'modal-'+item.id" title="Add to spec categories">
                      <div class="row">
                        <div class="col-md-12">
                          <label>Choose Category</label>
                          <v-select  v-model="modal.category" @input="LoadSubCategory()" class="lss-form-select" placeholder="Choose Category" label="text"  :options="CategoriesData"></v-select>

                        </div>
                        <div class="col-md-12 mt-2">
                          <label>Choose Sub Category</label>
                          <v-select  v-model="modal.subcategory" class="lss-form-select" placeholder="Choose Sub Category" label="text" @input="LoadSubCategory" :options="SubCategoriesData"></v-select>

                        </div>
                      </div>
                      <b-button class="mt-3 float-end" style="margin-left:10px;" variant="warning" @click="saveSpec(item.id);" block >Save</b-button>
                      <b-button class="mt-3 float-end" variant="danger" block @click="closeModal();">Close</b-button>
                    </b-modal>

                  </div>
                  <b-dropdown id="dropdown-aria" variant="default" class="float-end">

                    <template #button-content>
                      <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                        <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                      </svg>
                    </template>


                    <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="ViewItem(item.id)">View</b-dropdown-item-button>
                    <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="EditItem(item.id)">Edit</b-dropdown-item-button>

                  </b-dropdown>
                </div>
              </div>
            </div>





          </div>

          <div class="row mt-2">
            <div class="col-md-3">
              <pagination :data="ResponseData" @pagination-change-page="LoadData"></pagination>
            </div>
          </div>

        </div>




      </div>


    </div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "Application",
  data() {
    return {
      keyword:'',
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1,
      ResponseData:{},
      form:{
        name:this.$route.query.name,
        surname:this.$route.query.surname,
        mobile:this.$route.query.mobile,
        email:this.$route.query.email,
        gender:this.$route.query.gender,
        min_height:this.$route.query.min_height,
        max_height:this.$route.query.max_height,
        min_weight:this.$route.query.min_weight,
        max_weight:this.$route.query.max_weight,
        eye:this.$route.query.eye,
        hair:this.$route.query.hair,
        ethnic:this.$route.query.ethnic,
        language:this.$route.query.language,
        type:this.$route.query.type,
        clothing:this.$route.query.clothing,
        foot:this.$route.query.foot,
      },
      modal:{
        category:'',
        subcategory:''
      },
      TypeData:{},
      EyeData:{},
      HairData:{},
      EthnicData:{},
      LanguageData:{},
      ClothingData:{},
      FootData:{},
      SubCategoriesData:[],
      CategoriesData:[]
 /*     vital:this.$route.query.vitalInformation,
      vital2:this.$route.query.vitalInformation2*/
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{
    submit : function(){
      this.$refs.form.submit();
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    showModalSpec(id){


    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },

    onCancel() {

    },

    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },
    LoadData(page = 1){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/application/load_data?page=' + page,{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params:{
          name:this.$route.query.name,
          surname:this.$route.query.surname,
          mobile:this.$route.query.mobile,
          email:this.$route.query.email,
          gender:this.$route.query.gender,
          min_height:this.$route.query.min_height,
          max_height:this.$route.query.max_height,
          min_weight:this.$route.query.min_weight,
          max_weight:this.$route.query.max_weight,
          eye:this.$route.query.eye,
          hair:this.$route.query.hair,
          ethnic:this.$route.query.ethnic,
          language:this.$route.query.language,
          type:this.$route.query.type,
          clothing:this.$route.query.clothing,
          foot:this.$route.query.foot,
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });
    },
    LoadCategory(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/load_category_data',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {

            this.CategoriesData = response.data;
          });
    },
    LoadSubCategory(){
      //SubCategoriesData
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/categories/load_sub_category_data/'+this.modal.category.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {

            this.SubCategoriesData = response.data;
          });
    },
    deleteItem(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/application/delete/'+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.LoadData();
          });
    },
    EditItem(id){
      this.$router.push({ name: 'EditApplication', params: { id: id  } });
    },
    ViewItem(id){
      this.$router.push({ name: 'ApplicationView', params: { id: id  } });
    },
    getFilter(){
      if(this.keyword.length>=3 || this.keyword===''){
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        this.isLoading = true;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/ethnic/load_data?'+'keyword='+this.keyword,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              this.ResponseData = response.data;
              this.isLoading = false
            });
      }
    },

    LoadTypes(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/types/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.TypeData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadEye(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/eye/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.EyeData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadHair(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/hair/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.HairData=res.data.data;
          })
          .catch((error) => {

          });

    },
    closeModal(){
      location.reload();
    },
    LoadEtnic(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/ethnic/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.EthnicData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadLanguage(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/languages/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.LanguageData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadClothingSize(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/clothing/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.ClothingData=res.data.data;
          })
          .catch((error) => {

          });

    },
    LoadFootSize(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/foot/load_data', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.FootData=res.data;
          })
          .catch((error) => {

          });

    },
    ClearFilter(){
      this.$router.push('Application');
      this.$refs['my-modal'].hide();
      this.LoadData();

    },
    saveSpec(id){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/application/save_spec_categories/'+id, this.modal,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
             location.reload();
          })
          .catch((error) => {

          });
    }


  },
  mounted() {

    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;

    this.LoadCategory();

    this.LoadTypes();
    this.LoadEye();
    this.LoadHair();
    this.LoadEtnic();
    this.LoadLanguage();
    this.LoadClothingSize();
    this.LoadFootSize();
    this.LoadData();
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>