<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">



      <div class="dashboard-head-line">
        <div class="sent_container">
          <div class="row align-items-center">
            <div class="col-12 col-sm-6">
              <h5 class="head-title">Dashboard</h5>
            </div>
            <div class="col-12 col-sm-6 text-end text-sm-right mt-20 mt-sm-0">

            </div>
          </div>
        </div>
      </div>

<!--      <div class="sent_container mt-20">
        <table class="custom-table-bordered">
          <thead class="custom-thead-light">
          <tr class="text-center">
            <th scope="col">Invoice #</th>
            <th scope="col">Client</th>
            <th class="dc-none" scope="col">Taxcode</th>
            <th class="dc-none" scope="col">Contact Person</th>
            <th scope="col">Amount</th>
            <th class="dc-none" scope="col">Sum</th>
            <th class="dc-none" scope="col">Created at</th>
            <th scope="col">Payment at</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center">
            <th scope="row">20210101</th>
            <td>Giorgi Katsarava</td>
            <td class="dc-none">123123123123</td>
            <td class="dc-none">Nick Monkey</td>
            <td>100,000,00 $</td>
            <td class="dc-none">100,000,00 $</td>
            <td class="dc-none">2021/03/12</td>
            <td>2021/03/12</td>
          </tr>
          </tbody>
        </table>
        <div class="row mt-20">
          <div class="col-12 text-end">
            <button class="btn-sent-yellow-lg">See more</button>
          </div>
        </div>
      </div>-->

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Header from '@/components/Header.vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "Dashboard",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      name:'',
      SumSendAmount:0,
      isSendLoading:false,
      Period:1
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {

    },
    logout(){
      localStorage.removeItem('user');
      this.$router.push('/signin');
    },


  },
  mounted() {
    const user_info=JSON.parse(localStorage.getItem('user'));
    this.name=user_info.user.name;


    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>


@keyframes ldio-2ts2g6f7w1k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2ts2g6f7w1k div {
  position: absolute;
  width: 47px;
  height: 47px;
  border: 10px solid #0a0a0a;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-2ts2g6f7w1k div {
  animation: ldio-2ts2g6f7w1k 1s linear infinite;
  top: 56px;
  left: 50px
}
.loadingio-spinner-rolling-ud5ejxirre {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-2ts2g6f7w1k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2ts2g6f7w1k div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>