<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>

    <Header/>
    <div class="main_dashboard">

      <div class="sent_container mt-20">
        <div class="col-12 col-sm-12 mb-3">
          <div class="row">
            <div class="col-6 col-md-3">
              <h5 class="head-title mt-2">Users</h5>
            </div>
            <div class="col-12 col-md-12 mt-3">
              <div class="row">
                <div class="col-md-3">



                  <input type="text" class="lss-form-control" v-on:keyup="getFilter" v-model="keyword" placeholder="Search..." aria-label="Search">




                </div>
                <div class="col-md-9 text-end">
                  <a href="/Users/create">
                    <button class="btn-sent-yellow-lg">+ Add new</button>
                  </a>
                </div>
              </div>

            </div>
          </div>


        </div>
        <table class="custom-table-bordered" v-if="!isLoading">
          <thead class="custom-thead-light">
          <tr class="text-center">
            <th scope="col">#</th>
            <th scope="col" width="400" class="text-start">Name</th>
            <th scope="col" width="400" class="text-start">Email</th>
            <th class="col dc-none" scope="col">Created at</th>
            <th class="col" scope="col">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-center" v-for="post in ResponseData.data" :key="post.id">
            <th scope="row">{{ post.id }}</th>
            <td class=" text-start">
              {{ post.name }}
            </td>
            <td class=" text-start">
              {{ post.email }}
            </td>

            <td class="dc-none">{{ post.created_at }}</td>
            <td class="" v-if="post.sms_check==0">
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                <circle id="Ellipse_6" data-name="Ellipse 6" cx="4" cy="4" r="4" fill="#fd7b7b"/>
              </svg>

              Deactive
            </td>
            <td>



              <b-dropdown id="dropdown-aria" variant="default" class="m-2">

                <template #button-content>
                  <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="5" height="23" viewBox="0 0 5 23">
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" fill="#c4c4c4"/>
                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)" fill="#c4c4c4"/>
                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)" fill="#c4c4c4"/>
                  </svg>
                </template>


                <b-dropdown-header id="dropdown-header-1">Groups</b-dropdown-header>
                <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="EditUser(post.id)">Edit</b-dropdown-item-button>
                <b-dropdown-item-button aria-describedby="dropdown-header-1" v-on:click="DeleteUser(post.id)">Delete</b-dropdown-item-button>
              </b-dropdown>

            </td>

          </tr>

          </tbody>
        </table>

        <div class="row mt-2">
          <div class="col-md-3">
            <pagination :data="ResponseData" @pagination-change-page="getResults"></pagination>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "@/components/Header";
import axios from "axios";

export default {
  name: "Users",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      keyword:'',
      name:'',
      ResponseData:{}
    }
  },
  components: {
    Loading,
    Header
  },
  methods:{

    onCancel() {
    },
    EditUser(id){
      this.$router.push({ name: 'UserEdit', params: { id: id  } })
    },
    DeleteUser(id){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.delete(process.env.VUE_APP_BACKEND_URL+'/users/delete_user_by_id/'+id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            location.reload();
          });
    },
    getResults(page = 1) {
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/users/get_all_users?page=' + page,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.isLoading = false;
            this.ResponseData = response.data;
          });
    },
    getFilter(){
      if(this.keyword.length>=3 || this.keyword===''){
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        this.isLoading = true;
        axios.get(process.env.VUE_APP_BACKEND_URL+'/users/get_all_users?'+'keyword='+this.keyword,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              this.ResponseData = response.data;
              this.isLoading = false
            });
      }
    },
  },

  mounted() {
    this.getResults();
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }
}
</script>

<style scoped>

</style>