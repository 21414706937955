<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <header class="login_header">
      <div class="container-fluid p-0 pl-sm-3 pr-sm-3">
        <div class="row">
          <div class="col-12">
            <a href="/">
            </a>
          </div>
        </div>
      </div>
    </header>

    <section id="login_wrapper">
      <div class="login_position">
        <div class="login_box card border-0">
          <div class="card-body border-0">
            <form v-on:submit.prevent="submitForm" @keydown="clearError" class="row">

              <div class="col-12">
                <h1>Password Recovery</h1>
              </div>

              <div class="col-12 custom-form-group">
                <div class="alert alert-success" v-if="success" role="alert">
                  Congratulations! the reset password link was send to email.
                </div>
                <label class="custom-input-label" for="email">Email</label>
                <input id="email" type="email" name="email" v-model="form.email" class="lss-form-control" placeholder="example@example.com">
              </div>
              <div class="col-12 d-block d-sm-flex justify-content-between align-items-center mt-40">
                <div class="d-block d-md-flex text-center mt-4 mt-sm-0">
                  <div class="mr-0 mr-sm-3">Already have an account?</div>
                  <a href="/signin" class="ms-1">Sign In</a>
                </div>
                <button class="btn-sent-yellow-lg">Recover</button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "Recover",
  data(){
    return{
      success:false,
      isLoading: false,
      fullPage: true,
      isActive: true,
      form:{
        email:''
      }
    }
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {

    },
    submitForm() {

      this.isLoading = true;
      axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/recover-password', this.form)
          .then((res) => {

            this.isLoading = false
            this.success=true

            setTimeout(() => {
              this.success = false;
              this.form.email='';
              this.$router.push('/signin')

            }, 3000)

          })
          .catch((error) => {
            this.isLoading = false
            var error_response = error.response.data;
            if (error.response.status == 422) {
              this.setErrors(error.response.data.errors);
            } else {
              this.setErrors(error.response.data.message);
            }
          });
    },
    onFailure(message) {
      this.error = true;
    },
    setErrors(errors) {
      this.errors = errors;
    },
    hasError(fieldName) {
      return (fieldName in this.errors);
    },
    clearError(event) {
      delete this.errors[event.target.name]
    }
  },
  mounted() {



    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300)
  }

}
</script>

<style scoped>

</style>